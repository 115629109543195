import * as yup from "yup";
import moment from 'moment';
import axios from "axios";
const validationsForm =  yup.object().shape({
  new_name: yup
    .string().required("Required"),
  emailaddress: yup
    .string()
    .email("Email address is not valid")
    .required("Required"),
  new_rmecode: yup
  .string()
  .required("Required"),
  new_lastworkingday: yup.
  string()
  .required("Required"),
  gtoken: yup.
  string()
  .required("Required"),
  new_nid: yup.string()
    .when('new_egyptian', {
        is: true,
        then:  schema => 
          schema.required("National ID is Required")
        .matches(/^\d{14}$/, 'National ID must be exactly 14 digits only'),
 
        otherwise: schema => schema.required("Passport is Required")
        .matches(/^[A-Za-z0-9]{6,20}$/, 'Passport must be alphanumeric and 6-20 characters long')
      }),

});

export default validationsForm;
